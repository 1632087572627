const Footer: React.FC = () => {
  return (
    <div className="w-full bg-transparent">
      <div className="w-full h-px bg-grey-600" />
      <footer className="w-full px-6 py-8">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <span className="text-grey-300 mb-4 md:mb-0">
            © Sia Precision Education
          </span>
          <nav className="flex flex-col md:flex-row">
            {/* <a
              className="ml-0 md:ml-4 text-grey-300 hover:underline hover:text-sky-500 mb-2 md:mb-0"
              href="/contactus"
            >
              Contact Us
            </a> */}
            <a
              className="ml-0 md:ml-4 text-grey-300 hover:underline hover:text-sky-500"
              href="/privacypolicy"
            >
              Privacy Policy
            </a>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
