"use client";
import Image from "next/image";
import { motion } from "framer-motion";

type ValueProps = {
  title: string;
  description: string;
  image: string;
  reverse?: boolean;
};

const formatText = (text: string) => {
  return text
    .replace(/\*(.*?)\*/g, '<strong class="text-base-white">$1</strong>') // Bold text stays white
    .replace(/\n/g, "<br/>"); // Converts line breaks
};

const ValueProposition = ({ title, description, image, reverse }: ValueProps) => {
  return (
    <motion.div
      className={`flex flex-col md:flex-row items-center gap-8 ${reverse ? "md:flex-row-reverse" : ""}`}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      {/* Image Section */}
      <div className="w-full md:w-1/2">
        <Image
          src={image}
          alt={title}
          width={800}
          height={500}
          className="shadow-lg"
        />
      </div>

      {/* Text Section */}
      <div className="w-full md:w-1/2 text-left">
        <h2 className="text-2xl md:text-3xl font-bold text-grey-100">{title}</h2>
        <p
          className="mt-3 text-grey-300"
          dangerouslySetInnerHTML={{ __html: formatText(description) }}
        />
      </div>
    </motion.div>
  );
};

const ValuePropositions = () => {
  return (
    <section className="w-full max-w-7xl mx-auto space-y-16 py-16">
      <ValueProposition
        title="One Platform"
        description={`Tired of juggling flashcards in Anki, notes in Notion, and practice quizzes on another site? 
        \n*Cortex is the first AI-powered study system that auto-organizes your materials, creates personalized study plans, and generates quizzes—all in one place.* 
        \nNo more switching tabs or wasting time manually setting up your study tools.`}
        image="/images/FlashcardQuiz.png"
      />
      <ValueProposition
        title="Effortless Learning"
        description={`Most AI study tools just generate flashcards. 
        \n*Cortex goes beyond that—analyzing your study habits, identifying weak spots, and structuring your study sessions automatically.* 
        \nNo more guesswork—just smarter studying, with personalized quizzes, real-time progress tracking, and AI-powered recommendations tailored to how you learn best.`}
        image="/images/Analytics.png"
        reverse
      />
      <ValueProposition
        title="Your Digital Brain"
        description={`Struggling with scattered PDFs, lecture slides, and messy notes? 
        \n*Cortex acts as your AI-powered study assistant, automatically categorizing your materials, extracting key concepts, and summarizing key points.* 
        \nIt even suggests what to review next based on your past performance—so you can stop organizing and start learning smarter.`}
        image="/images/DigitalAssistant.png"
      />
    </section>
  );
};

export default ValuePropositions;
