"use client";
import Image from "next/image";
import { motion } from "framer-motion";

const StudyComparison = () => {
  return (
    <section className="w-full max-w-7xl mx-auto py-16 text-center">
      {/* Heading */}
      <motion.h2
        className="text-2xl md:text-3xl font-bold text-grey-100"
        initial={{ opacity: 0, y: -10 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        The Only Study Platform Built for the Way You Actually Learn.
      </motion.h2>

      {/* Subtext */}
      <motion.p
        className="mt-4 text-grey-300 text-lg max-w-2xl mx-auto"
        initial={{ opacity: 0, y: -10 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.7, ease: "easeOut", delay: 0.2 }}
      >
        AI flashcards and quizzes are great—but they don’t solve the real problem.
        Cortex isn’t just a study tool; it’s a complete system to help you manage
        overwhelming coursework, stay organized, and actually retain what you learn.
      </motion.p>

      {/* Comparison Cards */}
      <motion.div
        className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-6 border border-grey-600 rounded-xl p-6"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        {/* Traditional Study Methods */}
        <div className="flex flex-col items-center p-6">
          <Image
            src="/images/studyapps.png"
            alt="Traditional Study Methods"
            width={200}
            height={200}
            className="mb-6"
          />
          <h3 className="text-xl font-semibold text-base-white">Traditional Study Methods</h3>
          <p className="mt-2 text-grey-300">
            Juggling multiple apps results in disorganization, inefficiency, and
            information overload.
          </p>
        </div>

        {/* The Learning Cortex */}
        <div className="flex flex-col items-center p-6 border-t md:border-t-0 md:border-l border-grey-600">
          <Image
            src="/images/learningcortex.png"
            alt="The Learning Cortex"
            width={200}
            height={200}
            className="mb-6"
          />
          <h3 className="text-xl font-semibold text-base-white">The Learning Cortex</h3>
          <p className="mt-2 text-grey-300">
            A single AI-powered learning platform results in streamlined studying, 
            improved retention, and optimized efficiency.
          </p>
        </div>
      </motion.div>
    </section>
  );
};

export default StudyComparison;
