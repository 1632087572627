import { useEffect, useRef } from "react";

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string;
}

export default function VideoModal({
  isOpen,
  onClose,
  videoUrl,
}: VideoModalProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") onClose();
    };

    if (isOpen) {
      window.addEventListener("keydown", handleEscapeKey);
      return () => window.removeEventListener("keydown", handleEscapeKey);
    }

    return () => window.removeEventListener("keydown", handleEscapeKey);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <dialog
      aria-labelledby="video-modal-title"
      aria-modal="true"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
    >
      <div className="relative w-full max-w-6xl h-auto p-4 bg-grey-700 rounded-lg">
        <button
          aria-label="Close video"
          className="absolute top-2 right-2 text-base-white text-2xl font-bold hover:text-grey-300"
          onClick={onClose}
          type="button"
        >
          &times;
        </button>
        {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
        <video
          className="w-full h-auto object-cover rounded-lg"
          controls={true}
          ref={videoRef}
          src={videoUrl}
        />
      </div>
    </dialog>
  );
}
