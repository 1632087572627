"use client";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Image from "next/image";

const studySteps = [
  {
    id: "create-course",
    title: "Create Course",
    subtitle: "Set up your first course in under 60 seconds!",
    description:
      "Cortex structures everything for you. Just add your course name and some details, and Cortex takes care of the organization—so you spend less time sorting and more time studying.",
    image: "/images/create-course.png",
  },
  {
    id: "upload-content",
    title: "Upload Content",
    subtitle: "Drop in your PDFs, slides, or notes—Cortex handles the rest.",
    description:
      "No more endless folders or losing track of important documents. Upload lecture slides, study guides, or even handwritten notes, and Cortex auto-organizes everything.",
    image: "/images/upload.png",
  },
  {
    id: "study-session",
    title: "Study Session",
    subtitle: "All Your Study Materials, One Seamless Session",
    description:
      "No more switching between different courses—select and study multiple files from across your courses in a single, streamlined session. Whether you're preparing for the MCAT or reviewing for finals, this feature helps you stay organized and focused by keeping all your study materials in one place.",
    image: "/images/study-session.png",
  },
  {
    id: "learning-assistant",
    title: "Learning Assistant",
    subtitle: "Stuck? Ask Cortex anything—your AI tutor is always ready.",
    description:
      "Cortex’s AI breaks down complex topics, summarizes notes, and even generates quick quizzes based on your study materials.",
    image: "/images/learning-center.png",
  },
  {
    id: "test-knowledge",
    title: "Test Knowledge",
    subtitle: "No more passive studying—Cortex builds custom flashcards & quizzes for you.",
    description:
      "Cortex auto-generates personalized quizzes to reinforce key concepts, helping you retain information faster and ace your exams.",
    image: "/images/FlashcardQuiz.png",
  },
];

const StudyProcess = () => {
  const [activeTab, setActiveTab] = useState(studySteps[0].id);

  return (
    <section className="w-full max-w-6xl mx-auto px-6 py-16 text-center">
      {/* Header */}
      <motion.h2
        className="text-2xl md:text-3xl font-bold text-base-white"
        initial={{ opacity: 0, y: -10 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        Welcome to Your Learning Cortex.
      </motion.h2>
      <motion.p
        className="mt-3 text-grey-300 text-lg max-w-2xl mx-auto"
        initial={{ opacity: 0, y: -10 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.7, ease: "easeOut", delay: 0.2 }}
      >
        Your AI-powered learning system is here. Let’s walk you through how it works.
      </motion.p>

      {/* Tabs */}
      <div className="flex flex-wrap justify-center gap-3 my-8 ">
        {studySteps.map((step) => (
          <button
            key={step.id}
            className={`px-4 py-2 text-sm md:text-base font-medium rounded-full border border-grey-600 transition-all duration-300
              ${activeTab === step.id ? "bg-sky-500/50 text-base-white shadow-lg" : "text-grey-200 bg-grey-800/50 hover:text-white hover:bg-grey-600"}`}
            onClick={() => setActiveTab(step.id)}
          >
            {step.title}
          </button>
        ))}
      </div>

      {/* Content */}
      <div className="relative bg-grey-800/10 p-6 mt-8 rounded-lg shadow-lg border border-grey-600 text-center">
        <AnimatePresence mode="wait">
          {studySteps.map(
            (step) =>
              activeTab === step.id && (
                <motion.div
                  key={step.id}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.5 }}
                >
                  <h3 className="text-xl md:text-2xl font-semibold text-base-white">
                    {step.subtitle}
                  </h3>
                  <p className="mt-2 text-grey-300">{step.description}</p>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>

      {/* Image Display */}
      <div className="mt-6">
        <AnimatePresence mode="wait">
          {studySteps.map(
            (step) =>
              activeTab === step.id && (
                <motion.div
                  key={step.id}
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={{ duration: 0.5 }}
                >
                  <Image
                    src={step.image}
                    alt={step.title}
                    width={800}
                    height={400}
                    className=" mx-auto"
                  />
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default StudyProcess;
