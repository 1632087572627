"use client";
import { motion } from "framer-motion";

const studentTypes = [
  {
    title: "Premed Students",
    description:
      "Struggling to master the MCAT while keeping up with coursework? Cortex breaks down complex topics, tracks your weak areas, and optimizes your prep—so you walk into test day confident and prepared.",
  },
  {
    title: "Medical Students",
    description:
      "Balancing Step 1/2 prep, coursework, and rotations can feel impossible. Cortex structures your study sessions, reinforces weak areas, and keeps you on track for USMLE, patient case reviews, and class exams.",
  },
  {
    title: "Nursing Students",
    description:
      "From pharmacology to patient care, Cortex helps you retain critical nursing concepts with AI-generated NCLEX-style questions, adaptive quizzes, and study guides—so you're ready for both the classroom and the hospital floor.",
  },
  {
    title: "PA Students",
    description:
      "Between PANCE prep, clinical rotations, and coursework, staying organized is tough. Cortex simplifies case-based learning, medical decision-making, and board prep—so you stay ahead in both exams and patient care.",
  },
];

const WhoItsFor = () => {
  return (
    <section className="w-full max-w-8xl mx-auto py-16 text-center">
      {/* Header */}
      <motion.h2
        className="text-2xl md:text-3xl font-bold text-grey-100"
        initial={{ opacity: 0, y: -10 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        Who It’s For
      </motion.h2>
      <motion.p
        className="mt-3 text-grey-300 text-lg max-w-2xl mx-auto"
        initial={{ opacity: 0, y: -10 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.7, ease: "easeOut", delay: 0.2 }}
      >
        Used By Students Who Are Shaping the Future of Healthcare
      </motion.p>

      {/* Student Categories Grid */}
      <motion.div
        className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-6"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        {studentTypes.map((student, index) => (
          <div
            key={index}
            className="p-6 border border-grey-600 rounded-xl shadow-lg bg-grey-800/10 text-left flex flex-col h-full"
          >
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-base-white">{student.title}</h3>
              <p className="mt-2 text-grey-300">{student.description}</p>
            </div>
          </div>
        ))}
      </motion.div>
    </section>
  );
};

export default WhoItsFor;
