"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Menu, X, ChevronDown } from "lucide-react";
import { useState } from "react";
import { NavLinks } from "./NavLinks";
import type { Feature } from "./types";
import { useHideOnScroll } from "../_hooks/useHideOnScroll";
import Logo from "@/public/Logo.svg?url";

const features: Feature[] = [
  { name: "AI Flashcards", url: "/features/flashcards", id: "flashcards" },
  { name: "AI Quizzes", url: "/features/quizzes", id: "quizzes" },
  { name: "Course Management", url: "/features/course-creation", id: "course-creation" },
  { name: "Learning Assistant", url: "/features/ai-tutor", id: "ai-tutor" },
];

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [featuresOpen, setFeaturesOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<ReturnType<typeof setTimeout> | undefined>();
  const pathname = usePathname();
  const showNavbar = useHideOnScroll({ threshold: 10, initialState: true });

  const isActive = (path: string) => pathname === path;
  const activeClass = "text-sky-500";
  const inactiveClass = "hover:bg-grey-600 hover:text-grey-200";

  const handleMouseEnter = () => {
    if (hoverTimeout) clearTimeout(hoverTimeout);
    setFeaturesOpen(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => setFeaturesOpen(false), 200);
    setHoverTimeout(timeout);
  };

  return (
    <nav className={`bg-base-black/80 text-base-white fixed left-0 top-0 z-50 w-full transition-transform duration-300 ${showNavbar ? "translate-y-0" : "-translate-y-full"}`}>
      <div className="mx-auto max-w-7xl px-4">
        <div className="flex h-16 items-center justify-between">
          <Link href="/" passHref={true}>
            <Image alt="Sia Learning Logo" height={200} width={200} src={Logo} priority />
          </Link>

          {/* Desktop Navigation - Features, Pricing, and About Us */}


          
          <div className="hidden md:flex items-center space-x-6">
             <Link className={`hover:text-sky-500 ${isActive("/aboutus") ? "text-sky-500" : ""}`} href="/aboutus">
              About Us
            </Link>
            <div className="relative group">
              <button
                className="flex items-center space-x-1 hover:text-sky-500"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <span>Features</span>
                <ChevronDown className={`transition-transform ${featuresOpen ? "rotate-180" : ""}`} />
              </button>
              {featuresOpen && (
                <div
                  className="absolute left-0 top-full mt-2 w-48 rounded-lg bg-grey-700 p-2 shadow-lg"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {features.map((feature) => (
                    <Link
                      key={feature.id}
                      href={feature.url}
                      className={`block px-4 py-2 rounded-md ${isActive(feature.url) ? "text-sky-500" : "hover:bg-grey-600"}`}
                    >
                      {feature.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <Link className={`hover:text-sky-500 ${isActive("/pricing") ? "text-sky-500" : ""}`} href="/pricing">
              Pricing
            </Link>


          </div>

          {/* Desktop - Login & Sign Up */}
          <div className="hidden md:flex items-center space-x-4">
            <Link className={`${isActive("/signin") ? "text-gray-300" : "hover:text-gray-300"}`} href="/signin">
              Login
            </Link>
            <Link className={`rounded-md bg-blue-600 px-4 py-2 ${isActive("/signup") ? "bg-blue-700" : "hover:bg-blue-700"}`} href="/signup">
              Sign Up
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button className="md:hidden" onClick={() => setIsOpen(!isOpen)} type="button">
            {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="space-y-2 py-4 md:hidden">
            <div className="relative">
              <button className={`flex w-full items-center justify-between rounded-xl p-2 ${pathname.startsWith("/features") ? "bg-grey-600" : ""}`} onClick={() => setFeaturesOpen(!featuresOpen)} type="button">
                Features
                <ChevronDown className={`transition-transform ${featuresOpen ? "rotate-180" : ""}`} />
              </button>
              {featuresOpen && (
                <div className="pl-4">
                  {features.map((feature) => (
                    <Link key={feature.id} className={`block py-2 ${isActive(feature.url) ? "text-sky-500" : ""}`} href={feature.url}>
                      {feature.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <Link className={`block p-2 ${isActive("/pricing") ? "bg-grey-600" : ""}`} href="/pricing">
              Pricing
            </Link>

            <Link className={`block p-2 ${isActive("/aboutus") ? "bg-grey-600" : ""}`} href="/aboutus">
              About Us
            </Link>

            <Link className={`block p-2 ${isActive("/signin") ? "bg-grey-600" : ""}`} href="/signin">
              Login
            </Link>
            <Link className={`block rounded-md bg-blue-600 p-2 text-center ${isActive("/signup") ? "bg-blue-700" : "hover:bg-blue-700"}`} href="/signup">
              Sign Up
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
