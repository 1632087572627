"use client";
import { motion } from "motion/react";
import CallToAction from "./_components/CallToAction";
import FAQSection from "./_components/FAQsection";
import Hero from "./_components/Hero";
import NavBar from "./_components/Navbar";
import Testimonial from "./_components/Testimonial";
import ValuePropositions from "./_components/ValuePropositions";
import StudyComparison from "./_components/StudyComparison";
import StudyProcess from "./_components/StudyProcess";
import WhoItsFor from "./_components/WhoItsFor";
import Footer from "./_components/Footer"; // Import Footer

const staggerContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

export default function LandingPage() {
  return (
    <>
      <div className="bg-gradient-to-t from-sky-950 via-grey-900 to-base-black min-h-screen scroll-smooth">
        <div className="relative max-w-7xl mx-auto">
          <NavBar />
        </div>

        <motion.div
          className="relative px-10 py-8 sm:px-4 sm:py-16 max-w-7xl justify-center align-middle mx-auto"
          variants={staggerContainer}
          initial="hidden"
          animate="show"
        >
          <Hero />
          <Testimonial />
          <ValuePropositions />
          <StudyComparison />
          <StudyProcess />
          <WhoItsFor />
          <FAQSection />
          <CallToAction />
        </motion.div>
      </div>
      <Footer />
    </>
  );
}
